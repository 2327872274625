<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"
                        >
                            <span>
                                <span>{{
                                    `${$get(
                                        getNotifyUsersDetails,
                                        'name',
                                        ''
                                    )} ${$get(
                                        getNotifyUsersDetails,
                                        'lastName',
                                        ''
                                    )}`
                                }}</span>
                            </span>
                        </p>

                        <span
                            ><b>{{ `${$t('notify-users:createdAt')}` }}:</b>
                            {{
                                `${$moment(
                                    getNotifyUsersDetails.createdAt
                                ).format(`DD-MM-YYYY`)}`
                            }}</span
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="9"
                        ><v-text-field
                            readonly
                            :value="
                                getNotifyUsersDetails.isAdmin
                                    ? $t('global:isAdmin')
                                    : $t('global:isUser')
                            "
                            :label="$t('notify-users:userType')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="5"
                        ><v-text-field
                            readonly
                            :value="getNotifyUsersDetails.contact.name"
                            :label="$t('notify-users:contact')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="5"
                        ><v-text-field
                            readonly
                            :value="`${getNotifyUsersDetails.login}`
                            "
                            :label="$t('notify-users:loginAndEmail')"
                        ></v-text-field>
                    </v-col>
                  
                    <v-col cols="4"
                        ><v-text-field
                            readonly
                            :value="`${$get(
                                getNotifyUsersDetails,
                                'phoneNumber',
                                `${$t('notify-users:noNumber')}`
                            )}`"
                            :label="$t('notify-users:phone')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- <v-col cols="4">
                        <v-checkbox
                            v-model="getNotifyUsersDetails.isActive"
                            readonly
                            :label="$t('notify-users:isActive')"
                        ></v-checkbox
                    ></v-col> -->
                    <v-col cols="4">
                        <v-checkbox
                            readonly
                            v-model="getNotifyUsersDetails.isBanned"
                            :label="$t('notify-users:isBanned')"
                        ></v-checkbox
                    ></v-col>
                    <v-col cols="4" class="d-flex align-center">
                        <span style="font-size: 14px;">{{
                            `${$t('notify-users:loginTry')}: ${
                                getNotifyUsersDetails.loginTries || 0
                            }`
                        }}</span>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="9"
                        ><v-text-field
                            readonly
                            :value="getNotifyUsersDetails.comments"
                            :label="$t('notify-users:comments')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters(['getNotifyUsersDetails', 'getRegistryDictionaries']),
    },
    methods: {},
}
</script>
