<template>
    <v-container>
        <Header
            :registryStatus="getNotifyUsersStatuses"
            :recordIndex="getNotifyUsersDetails.index"
            :registryTitle="getNotifyUsersRegistry.name"
            :registryUrl="'help-desk-users'"
            :actualStatus="getNotifyUsersDetails.status"
            :avatarData="getNotifyUsersDetails.createdBy"
            @status-changed="changeStatus"
        ></Header>
        <Main :items="items" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('help-desk-users:editInfo')"
                    :open="open"
                    :height="'640'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editNotifyUser()"
                                class="buttons--add margin--1 ml-4"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('help-desk-users:edit') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>

<script>
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Information from '../../../components/Registries/Notify-users/Details/Information.vue'
import AEContent from './../../../components/Registries/Notify-users/Modal/Content'
import Buttons from './../../../components/Registries/Notify-users/Modal/Buttons'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Information,
        AEContent,
        Buttons,
    },
    data() {
        return {
            open: false,
            openConfirm: false,
            items: [],
        }
    },
    computed: {
        ...mapGetters([
            'getNotifyUsersStatuses',
            'getNotifyUsersRegistry',
            'getNotifyUsersDetails',
        ]),
    },
    methods: {
        ...mapMutations([
            'setNotifyUsersDetails',
            'setNotifyUsersStatuses',
            'setNotifyUsersRegistry',
            'setNotifyUsersModal',
            'clearNotifyUsersErrors',
            'clearNotifyUsersModal',
        ]),
        ...mapActions([
            'fetchNotifyUsersRegistry',
            'fetchNotifyUsers',
            'updateNotifyUsersStatus',
        ]),
        changeStatus(status) {
            this.updateNotifyUsersStatus({
                status,
                id: this.getNotifyUsersDetails._id,
            })
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        async editNotifyUser() {
            this.open = true
            this.clearNotifyUsersErrors()
            this.setNotifyUsersModal()
        },
        closeModal() {
            this.open = false
            this.clearNotifyUsersErrors()
            this.clearNotifyUsersModal()
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchNotifyUsersRegistry')
        store.dispatch('fetchNotifyUsers', { id: to.params.id, next })
    },
}
</script>
