<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper
                    v-model="getNotifyUsersModal.step"
                    class="elevation-0"
                >
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getNotifyUsersErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('notify-users:basicInfo')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t('notify-users:basicInfo')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row
                                    ><v-col cols="6">
                                        <v-combobox
                                            v-model="
                                                getNotifyUsersModal.contact
                                            "
                                            :label="`${$t(
                                                'notify-users:contact'
                                            )}*`"
                                            hint="Zacznij pisać..."
                                            @keyup="
                                                setContacts($event.target.value)
                                            "
                                            :hide-no-data="hideContacts"
                                            :items="getContactsInput.items"
                                            item-text="name"
                                            item-value="_id"
                                            return-object
                                            :error-messages="
                                                getNotifyUsersErrors.stepOne
                                                    .fields.contact
                                            "
                                        >
                                            <!-- @input="assignID()" -->
                                        </v-combobox>
                                    </v-col></v-row
                                >
                                <v-row>
                                    <v-col cols="6" md="6">
                                        <v-text-field
                                            v-model="getNotifyUsersModal.name"
                                            :label="`${$t(
                                                'notify-users:name'
                                            )}*`"
                                            :error-messages="
                                                getNotifyUsersErrors.stepOne
                                                    .fields.name
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="6">
                                        <v-text-field
                                            v-model="
                                                getNotifyUsersModal.lastName
                                            "
                                            :label="`${$t(
                                                'notify-users:lastName'
                                            )}*`"
                                            :error-messages="
                                                getNotifyUsersErrors.stepOne
                                                    .fields.lastName
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row
                                    ><v-col cols="6" md="6">
                                        <v-text-field
                                            v-model="getNotifyUsersModal.login"
                                            :label="`${$t(
                                                'notify-users:login'
                                            )}*`"
                                            :rules="loginRules"
                                            :error-messages="
                                                getNotifyUsersErrors.stepOne
                                                    .fields.login
                                            "
                                        ></v-text-field> </v-col
                                    ><v-col cols="6" md="6">
                                        <v-text-field
                                            v-model="
                                                getNotifyUsersModal.phoneNumber
                                            "
                                            :label="`${$t(
                                                'notify-users:phone'
                                            )}*`"
                                            :error-messages="
                                                getNotifyUsersErrors.stepOne
                                                    .fields.phoneNumber
                                            "
                                        ></v-text-field> </v-col
                                ></v-row>
                                <v-row>
                                    <v-col class="py-1" cols="12" md="6">
                                        <v-text-field
                                            :rules="rules"
                                            :counter-value="
                                                v =>
                                                    v
                                                        ? v.length +
                                                          ` ${this.$t(
                                                              'profile:characters'
                                                          )}`
                                                        : 0 +
                                                          ` ${this.$t(
                                                              'profile:characters'
                                                          )}`
                                            "
                                            :append-icon="
                                                show1
                                                    ? 'mdi-eye'
                                                    : 'mdi-eye-off'
                                            "
                                            :class="
                                                checkLength(
                                                    getNotifyUsersModal.passwordAgain
                                                )
                                            "
                                            v-model="
                                                getNotifyUsersModal.password
                                            "
                                            :label="`${$t('users:password')}*`"
                                            :type="show1 ? 'text' : 'password'"
                                            counter
                                            @click:append="show1 = !show1"
                                            :error-messages="
                                                getNotifyUsersErrors.stepOne
                                                    .fields.password
                                            "
                                        ></v-text-field>
                                    </v-col>

                                    <v-col class="py-1" cols="12" md="6">
                                        <v-text-field
                                            :rules="rules"
                                            :counter-value="
                                                v =>
                                                    v
                                                        ? v.length +
                                                          ` ${this.$t(
                                                              'profile:characters'
                                                          )}`
                                                        : 0 +
                                                          ` ${this.$t(
                                                              'profile:characters'
                                                          )}`
                                            "
                                            :class="
                                                checkLength(
                                                    getNotifyUsersModal.passwordAgain
                                                )
                                            "
                                            :append-icon="
                                                show1
                                                    ? 'mdi-eye'
                                                    : 'mdi-eye-off'
                                            "
                                            v-model="
                                                getNotifyUsersModal.passwordAgain
                                            "
                                            :type="show2 ? 'text' : 'password'"
                                            counter
                                            minlength="8"
                                            @click:append="show2 = !show2"
                                            :error-messages="
                                                getNotifyUsersErrors.stepOne
                                                    .fields.passwordAgain
                                            "
                                            :label="`${$t(
                                                'users:passwordAgain'
                                            )}*`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-checkbox
                                            v-model="
                                                getNotifyUsersModal.isAdmin
                                            "
                                            :label="$t('notify-users:isAdmin')"
                                        ></v-checkbox
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-checkbox
                                            v-model="
                                                getNotifyUsersModal.isBanned
                                            "
                                            :label="$t('notify-users:isBanned')"
                                            @change="resetLoginTries()"
                                        ></v-checkbox
                                    ></v-col>
                                    <v-col cols="4" class="d-flex align-center">
                                        <span>{{
                                            `${$t('notify-users:loginTry')}: ${
                                                getNotifyUsersModal.loginTries ||
                                                0
                                            }`
                                        }}</span></v-col
                                    >
                                </v-row>

                                <v-row
                                    ><v-col cols="12" md="12" class="pt-0">
                                        <v-text-field
                                            v-model="
                                                getNotifyUsersModal.comments
                                            "
                                            :label="`${$t(
                                                'notify-users:comments'
                                            )}`"
                                        ></v-text-field> </v-col
                                ></v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            hideContacts: true,
            rules: [
                v =>
                    v
                        ? v.length == 0 ||
                          v.length >= 8 ||
                          this.$t('profile:shortPassword')
                        : true,
            ],
            loginRules: [
                v => !!v || 'Login jest wymagany',
                v => /.+@.+\..+/.test(v) || 'Proszę podać adres e-mail',
            ],
            show1: false,
            show2: false,
        }
    },
    computed: {
        ...mapGetters([
            'getNotifyUsersModal',
            'getNotifyUsersErrors',
            'getContactsInput',
        ]),
    },
    methods: {
        ...mapActions(['fetchContactsInputs']),
        ...mapMutations(['setContactsInput']),
        checkLength(v) {
            if (v && v.length >= 8) return 'success--text'
        },
        resetLoginTries() {
            this.getNotifyUsersModal.loginTries = 0
        },
        setContacts(search) {
            if (search.length >= 4) {
                this.setContactsInput({ search })
                this.fetchContactsInputs()
                this.hideContacts = false
            } else {
                this.getContactsInput.items = []
                this.hideContacts = true
            }
        },
    },
}
</script>
