var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3","xl":"3","lg":"3","md":"5","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"},[_c('span',[_c('span',[_vm._v(_vm._s(`${_vm.$get( _vm.getNotifyUsersDetails, 'name', '' )} ${_vm.$get( _vm.getNotifyUsersDetails, 'lastName', '' )}`))])])]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('notify-users:createdAt')}`)+":")]),_vm._v(" "+_vm._s(`${_vm.$moment( _vm.getNotifyUsersDetails.createdAt ).format(`DD-MM-YYYY`)}`))])])],1)],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6","xl":"6","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getNotifyUsersDetails.isAdmin
                                ? _vm.$t('global:isAdmin')
                                : _vm.$t('global:isUser'),"label":_vm.$t('notify-users:userType')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getNotifyUsersDetails.contact.name,"label":_vm.$t('notify-users:contact')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"readonly":"","value":`${_vm.getNotifyUsersDetails.login}`,"label":_vm.$t('notify-users:loginAndEmail')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"readonly":"","value":`${_vm.$get(
                            _vm.getNotifyUsersDetails,
                            'phoneNumber',
                            `${_vm.$t('notify-users:noNumber')}`
                        )}`,"label":_vm.$t('notify-users:phone')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"readonly":"","label":_vm.$t('notify-users:isBanned')},model:{value:(_vm.getNotifyUsersDetails.isBanned),callback:function ($$v) {_vm.$set(_vm.getNotifyUsersDetails, "isBanned", $$v)},expression:"getNotifyUsersDetails.isBanned"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"4"}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(`${_vm.$t('notify-users:loginTry')}: ${ _vm.getNotifyUsersDetails.loginTries || 0 }`))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getNotifyUsersDetails.comments,"label":_vm.$t('notify-users:comments')}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }